import {Controller} from "stimulus";
import {deleteGroup, removeMember, updateUserRole} from "../services/api_call";
import {openSnackbar} from "../services/shared";
import {AVATAR_COUNT, MIN_LIMIT, MIN_REQUEST} from "../services/constant";
import {I18n} from "../translations";
import {buttonLoader} from "../services/helper";

export default class extends Controller {

    addModalContent(event) {
        const action_source = event.currentTarget.dataset.actionSource

        let actionLabel = document.getElementById('modal_action')

        if (action_source === 'delete_group') {
            this.appendCont(
                `${I18n[window.currentLocale]['group_delete']}`,
                `${I18n[window.currentLocale]['delete_warning']}`,
                `${I18n[window.currentLocale]['delete']}`,
            )
            actionLabel.setAttribute('data-group-id', event.currentTarget.dataset.groupId)
            actionLabel.setAttribute('data-event', 'delete_group')
            // this may look like acho manish's api service but the difference is
            // manish's api service formulates a payload and sends the payload to backend
            // this will just build the required ui at the same time a form which we can simply fire to invoke the solution.
        } else if (action_source === 'remove_user') {
            this.appendCont(
                `${I18n[window.currentLocale]['remove_user']}`,
                `${I18n[window.currentLocale]['remove_user_warning']}`,
                `${I18n[window.currentLocale]['remove']}`,
            )
            actionLabel.setAttribute('data-users', event.currentTarget.dataset.userId)
            actionLabel.setAttribute('data-group-id', event.currentTarget.dataset.groupId)
            actionLabel.setAttribute('data-event', action_source)
        } else if (action_source === 'reject_request') {
            this.appendCont(
                `${I18n[window.currentLocale]['request_reject']}`,
                `${I18n[window.currentLocale]['request_reject_warning']}`,
                `${I18n[window.currentLocale]['reject']}`
            )
            actionLabel.setAttribute('data-users', event.currentTarget.dataset.userId)
            actionLabel.setAttribute('data-group-id', event.currentTarget.dataset.groupId)
            actionLabel.setAttribute('data-event', action_source)
        } else if (action_source === 'accept_request' || action_source === 'accept_all_requests') {
            this.appendCont(
                `${I18n[window.currentLocale]['accept_request']}`,
                action_source === 'accept_request' ? `${I18n[window.currentLocale]['accept_request_confirm']}` : `${I18n[window.currentLocale]['accept_all_request_confirm']}`,
                `${I18n[window.currentLocale]['accept']}`,
            )

            actionLabel.setAttribute('data-users', event.currentTarget.dataset.userId)
            actionLabel.setAttribute('data-group-id', event.currentTarget.dataset.groupId)
            actionLabel.setAttribute('data-event', action_source)
            actionLabel.setAttribute('data-membership', 'accept')
        }
    }

    appendCont(action, modal_content, action_name) {
        document.getElementById('modal_header').innerHTML = action
        document.getElementById('modal_content').innerHTML = modal_content
        document.getElementById('modal_action').innerHTML = action_name
    }

    performAction(event) { //action = remove_member || delete_group || accept/reject_join_request
        let group_id = event.currentTarget.dataset.groupId;
        let temp = event.currentTarget.dataset.users;
        let users = typeof (temp) === 'object' ? [...temp.split(',')] : temp.split(',');
        let membership = event.currentTarget.dataset.membership;
        let _event = event.currentTarget.dataset.event;

        buttonLoader('modal_action', 'loader');

        if (_event === 'remove_user') {
            removeMember(group_id, users, (response) => {
                let domId = response['data'][0]['user_uuid'];
                let dom = document.getElementById(`member-${domId}`) || document.getElementById(domId).parentElement
                dom.remove();

                document.getElementById('confirmation-modal').click();

                let member_count = response['data'][1]['members']
                let admin_count = response['data'][1]['admins']
                let followers = response['data'][1]['followers']
                document.getElementById('member-count').innerHTML = `${I18n[window.currentLocale]['members']} (${member_count})`;
                document.getElementById('admin-count').innerHTML = `${I18n[window.currentLocale]['admins']} (${admin_count})`;

                let avatarDom = document.getElementById('member-avatar');
                if (followers <= AVATAR_COUNT) avatarDom.lastElementChild.remove();
                else {
                    let new_member_count = followers - AVATAR_COUNT;
                    if(document.getElementById('initials-more_members')){
                        document.getElementById('initials-more_members').innerHTML = `+ ${new_member_count}`;
                    }
                }

                document.getElementById('group-followers-count').innerHTML = `${followers} ${I18n[window.currentLocale]['members']}`
                openSnackbar(I18n[window.currentLocale]['member_removal'], 'success');
                })
        } else if (_event === 'delete_group') {
            deleteGroup(group_id, () => {
                document.getElementById('confirmation-modal').click();
                setTimeout(() => {
                    openSnackbar(`${I18n[window.currentLocale]['delete_group']}`, 'success')
                    window.location.href = `${window.location.origin}/iam/groups/my_group`
                }, 1800);
            })
        } else if (_event === 'accept_request' || _event === 'accept_all_requests') {
            let user_params = []
            for (let i = 0; i < users.length; i++) {
                let user_uuid = users[i]
                let status = 'following'
                user_params.push({user_uuid, status})
            }
            updateUserRole(group_id, user_params, membership, _event, (response) => {
                let users = response.data.users;
                users.forEach(user => {
                    document.getElementById(`member-${user.uuid}`).remove();
                })
                document.getElementById('join-request-count').innerHTML = `${I18n[window.currentLocale]['join_request']} (${response.data.join_requests})`

                if (response.data.event === 'accept_all_requests' || response.data.join_requests < MIN_REQUEST) {
                    if(document.getElementById('approve-all-btn')) {
                        document.getElementById('approve-all-btn').remove();
                    }
                }

                document.getElementById('confirmation-modal').click()
                openSnackbar(I18n[window.currentLocale]['user_added_to_group'], 'success');
            })
        } else if (_event === 'reject_request') {
            removeMember(group_id, users, (response) => {
                let user = response.data[0].user_uuid;
                let join_requests = response.data[1].join_requests
                document.getElementById(`member-${user}`).remove();
                document.getElementById('join-request-count').innerHTML = `${I18n[window.currentLocale]['join_request']} (${join_requests})`
                document.getElementById('confirmation-modal').click();
                if(join_requests < MIN_REQUEST && document.getElementById('approve-all-btn')) {
                    document.getElementById('approve-all-btn').remove();
                }
                openSnackbar(I18n[window.currentLocale]['reject_request'], 'success')
            })
        }
    }

    updateRole(event) {
        let users = [JSON.parse(event.currentTarget.dataset.users)];
        let group_id = event.currentTarget.dataset.groupId;
        let role_action = event.currentTarget.dataset.roleAction;

        updateUserRole(group_id, users, role_action, null,(res) => {
            let action = res['data']['role_action'];
            let users = res['data']['users']
            let admin_count = res['data']['admins']
            let member_count = res['data']['members']

            for (let i = 0; i < users.length; i++) {
                let uuid = users[i].uuid;
                let dom = document.getElementById(`member-${uuid}`) || document.getElementById(uuid).parentNode;
                let new_dom = dom.cloneNode(true);
                dom.remove();

                if (action === 'promote_to_admin') {
                    let last_child = new_dom.querySelector('#dropdown-menu').lastElementChild;
                    let user_data = JSON.stringify({user_uuid: uuid, role: 'member'});
                    let _innerHTML = `<i class="material-icons-outlined text-[20px] mr-[8px]">change_history</i>${I18n[window.currentLocale]['demotion']}`;
                    last_child.setAttribute('data-role-action', 'revert_to_member');
                    last_child.setAttribute('data-users', user_data);
                    last_child.innerHTML = _innerHTML;

                    if (admin_count < MIN_LIMIT) {
                        document.getElementById('group-admins-card-list').appendChild(new_dom);
                    } else {
                        this.viewMore(group_id, 'admins');
                        break;
                    }
                } else {
                    let last_child = new_dom.querySelector('#dropdown-menu').lastElementChild;
                    let user_data = JSON.stringify({user_uuid: uuid, role: 'admin'});
                    let _innerHTML = `<i class="material-icons-outlined text-[20px] mr-[8px]">change_history</i>${I18n[window.currentLocale]['promotion']}`;
                    last_child.setAttribute('data-role-action', 'promote_to_admin');
                    last_child.setAttribute('data-users', user_data);
                    last_child.innerHTML = _innerHTML;

                    if (member_count < MIN_LIMIT) {
                        document.getElementById('group-members-card-list').appendChild(new_dom);
                    } else {
                        this.viewMore(group_id, 'members');
                        break;
                    }
                }
            }
            document.getElementById('admin-count').innerHTML = `${I18n[window.currentLocale]['admins']} (${admin_count})`;
            document.getElementById('member-count').innerHTML = `${I18n[window.currentLocale]['members']} (${member_count})`;

            if (action === 'promote_to_admin') openSnackbar(I18n[window.currentLocale]['member_promote'], 'success');
            else openSnackbar(I18n[window.currentLocale]['member_demote'], 'success');

        })
    }

    viewMore(uuid, type) {
        let value = type === 'admins' ? "admin_data" : "members_data"
        let dom =`<form method="post" action="/iam/view_more">
                                <button class="hidden cnc-btn cnc-btn--outline sm:inline-block" type="submit">View More</button>
                                <input type="hidden" name="id" value=${uuid}>
                                <input type="hidden" name="stream_target" value=${value}>
                              </form>`

        let id = type === 'admins' ? 'view-more-admin' : 'view-more-member'
        document.getElementById(id).innerHTML = dom;
    }
}
