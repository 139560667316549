import {Controller} from 'stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
    static targets = ['button', 'menu'];

    show() {
        this.menuTarget.classList.toggle('hidden');

        createPopper(this.buttonTarget, this.menuTarget, {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10]
                    }
                }
            ]
        });
    };

    hide(event) {

        if (event && (this.menuTarget.contains(event.target) || this.buttonTarget.contains(event.target))) {
            return;
        }

        this.menuTarget.classList.add('hidden');
    }
}
