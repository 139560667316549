import { Controller } from "stimulus"
import API from "../services/api"

export default class extends Controller {
	static targets = [ "email", "password", "output" ]

	sign_in() {
		let params = new URLSearchParams(window.location. search)
	}
}
