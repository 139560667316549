import {Controller} from 'stimulus';

export default class extends Controller {
    connect() {
        const tabsList = document.querySelectorAll('.cnc-tabs__tab')

        if( window.location.href.includes('overview')){
            this.appendClass(tabsList, 'overview_tab')
        } else {
            this.appendClass(tabsList, 'member_tab')
        }

        if (/Mobi/i.test(window.navigator.userAgent) === true) { // tabs in landing page
            const tabs = JSON.parse(this.element.dataset.items)
            let currentTab = this.element.querySelectorAll('.active')[0]

            if(currentTab.id === tabs[tabs.length -1]) {
                this.element.scrollLeft += this.element.scrollWidth;
            }
        }
    }

    appendClass(tabsList, id) {
        tabsList.forEach(tab => {
            if (tab.id === id) {
                tab.classList.add('cnc-tabs__tab--active')
            } else {
                tab.classList.remove('cnc-tabs__tab--active')
            }
        })
    }
}