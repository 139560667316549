import { Forms } from '@quantumms/cnc-stimulus-utils'

const addExceptions = (config, object, name) => {
	object.neglect = object.neglect || {}
	object.neglect[config.name] = true
}

const patternValidator = (config, object, name) => {
	object[name] = object[name] || []
	object[name].push(Forms.QuantumValidators.pattern(new RegExp(config['pattern']), config['error_name']))
}

export const EditValidations = {
	not_required: addExceptions,
	pattern: patternValidator
}
