import {Forms} from "@quantumms/cnc-stimulus-utils";
import {appendDom, setVdom} from "../services/shared";
import {pluck} from "../services/helper";

export default class extends Forms.ControlsController {
    state = {
        invalidTouched: (context) => {
            return !context.state.valid && context.control.touched;
        },
        validations: true
    }
    listeners = {
        valid: ['invalidTouched'],
        touched: ['invalidTouched'],
    }

    static targets = ['menu', 'input', 'selected', 'select'];

    group_members = [];
    form = document.getElementById('group_create');
    profile_picture = 'https://langur.club.selise.tech/app/assets/images/profile.jpg';

    onUpdateAction = (function () {
        let executed = false;
        let arr = [];
        return function () {
            if (!executed) {
                executed = true;
                if (this.element.dataset.groupMembers) {
                    let members = JSON.parse(this.element.dataset.groupMembers);
                    for (let i = 0; i < members.length; i++) {
                        let tmpHash = {};
                        tmpHash['id'] = members[i].id;
                        tmpHash['user_uuid'] = members[i].user_uuid;
                        arr.push(tmpHash);
                        this.display(members[i], true);
                    }
                }
            }
            this.group_members = [...arr];
        };
    })();

    connected() {
        if (window.location.href.includes('edit')) {
            this.onUpdateAction();
        }

        document.addEventListener("click", (evt) => {
            [this.inputTarget, this.selectTarget].includes(evt.target) ? this.showMenu() : this.removeMenu()
        }, false);
    }

    showMenu() {
        this.menuTarget.classList.remove('hidden')
    }

    removeMenu() {
        if (!this.menuTarget.classList.value.includes('hidden')) {
            this.menuTarget.classList.add('hidden')
        }
    }

    suggest() {
        let array = JSON.parse(this.inputTarget.dataset.value)
        let searchvalue = this.inputTarget.value;
        let result = []

        array.filter((record) => {
            if (record.name.toLowerCase().includes(searchvalue.toLowerCase())) {
                result.push(record.name)
                document.getElementById(`user_${record.uuid}`).style.display = "block"
            } else {
                document.getElementById(`user_${record.uuid}`).style.display = "none"
            }
        })

        if (result.length < 1) {
            this.removeMenu();  // hide list if search result is one
        } else {
            this.showMenu();
        }
    }

    display(event, edit) {
        if (pluck(this.group_members, 'user_uuid').includes(event.currentTarget?.dataset.uuid.replace('user_', ''))) return

        let name;
        let id = null;
        let uuid;
        let dp;

        if (edit) {
            id = event.id;
            uuid = event.user_uuid;
            name = event.name;
            dp = event.profile_picture || this.profile_picture;
        } else {
            uuid = event.currentTarget.dataset.uuid.replace('user_', '')
            name = event.currentTarget.dataset.value;
            dp = event.currentTarget.dataset.dp || this.profile_picture;
        }

        if (this.control._parent.get('members')) {
            if (!(this.group_members.includes(uuid))) {
                this.group_members.push({ id: id, user_uuid: uuid })
                setVdom(`/iam/get_group_member?label=${name}&uuid=${uuid}&imgLink=${dp}`,
                    'div', [], (vDom) => {
                        appendDom('group-member-list', vDom)
                    });
            }
        }

        this.control.setOnlyValues(this.group_members);

        if(this.group_members.length > 0) this.application.getControllerForElementAndIdentifier(this.form, 'group').form.controls.members.updateValidators();
    }

    cancel(event) {
        let members = this.control.value
        let parent = document.getElementById('group-member-list');
        let target = document.getElementById(event.currentTarget.dataset.uuid);

        parent.removeChild(target.parentNode);

        this.removeMember(members, event.currentTarget.dataset.uuid);

        this.control.setOnlyValues(members);

        if(this.control.value.length < 1) this.application.getControllerForElementAndIdentifier(this.form, 'group').form.controls.members.updateValidators(false);
    }

    removeMember(arr, member) {
        const index = arr.findIndex((e) => e.user_uuid === member);
        arr.splice(index, 1);
    }
}