import {Forms} from '@quantumms/cnc-stimulus-utils'
import {createPermissionSet, performPermissionToggle} from "../services/permission_set";

export default class extends Forms.FormsController {
	static targets = ['cancel']
	static values = {type: String}
	state = {
		invalid: (context) => {
			return !context.state.valid || context.state.disableButton
		},
		disableButton: false
	}
	listeners = {
		valid: ['invalid'],
		disableButton: ['invalid']
	}
	permissions = JSON.parse(this.element.dataset.permissions)

	connected() {
		document.getElementById('permission_modal_creation').click();
	}

	update(event) {
		let _data = performPermissionToggle(event);
	}

	create() {
		let data = {
			permission_set: {
				name: this.form.get('name').value,
				permissions: this.permissionData
			}
		}

		let _this = this;
		this.state.disableButton = true
		createPermissionSet(data, function (value) {
			_this.state.disableButton = false
			_this.cancelTarget.click();
		})
	}

	get permissionData() {
		let data = [];
		this.permissions.forEach(permission => {
			let obj = {
				parent_ref: permission.uuid,
				resource: permission.resource,
				disabled: false,
				actions: permission.actions
			}

			let parent = document.getElementById(`${permission.uuid}_permission_set`)

			if (parent.checked) {
				obj.disabled = true

				Object.keys(permission.actions).forEach(action => {
					let ele = document.getElementById(`${permission.uuid}_permission_${action}`)
					obj.actions[action] = ele.checked
				});
			}

			data.push(obj);
		})

		return data
	}

	get buildReference() {
		return {
			name: null,
			validators_reference: {}
		}
	}
}
