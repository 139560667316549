/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import Rails from "@rails/ujs"
//
// if (!customElements.get('turbo-frame'))  {
// 	require("@hotwired/turbo-rails");
// 	const Rails = require('@rails/ujs')
// 	Rails.start();
// }
require('stylesheets/application.scss')
import 'controllers'


// document.addEventListener('turbo:submit-start', () => {
// 	window.Turbo.navigator.delegate.adapter.progressBar.setValue(0);
// 	window.Turbo.navigator.delegate.adapter.showProgressBar();
// });
//
// document.addEventListener('turbo:submit-end', (e) => {
// 	window.Turbo.navigator.delegate.adapter.progressBar.setValue(1);
// 	window.Turbo.navigator.delegate.adapter.progressBar.hide();
//
// 	if (e.detail.success === false) {
// 		e.target.previousElementSibling.scrollIntoView(true)
// 	}
// });
//
// document.addEventListener('turbo:before-fetch-request', (e) => {
// 	window.Turbo.navigator.delegate.adapter.progressBar.setValue(0);
// 	window.Turbo.navigator.delegate.adapter.showProgressBar();
// });
//
// document.addEventListener('turbo:before-fetch-response', (e) => {
// 	window.Turbo.navigator.delegate.adapter.progressBar.setValue(1);
// 	window.Turbo.navigator.delegate.adapter.progressBar.hide();
// });

