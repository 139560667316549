import { Forms } from '@quantumms/cnc-stimulus-utils'
import {changePassword} from "../services/auth";

export default class extends Forms.FormsController {
	state = {
		invalid: (context) => {
			return !context.state.valid;
		}
	}
	listeners = {
		valid: ['invalid']
	}

	connected() {
		this.listenToPassword()
	}

	confirmationValidation(context) {
		return (control) => {
			if (!context.form) {
				return { password_mismatch: true }
			}

			return control.value === context.form.get('password').value ? null : { password_mismatch: true }
		}
	}

	listenToPassword() {
		this.form.get('password').valueChanges.subscribe(value => {
			if (!!this.form.get('password_confirmation').value) {
				this.form.get('password_confirmation').updateValidators();
			}
		})
	}

	update() {
		changePassword(this.form.value, () => {
			this.cancel()
			this.controls.forEach(control => {
				this.form.get(control).setValue(null)
			})
		})
	}

	cancel() {
		document.getElementById('password_change_modal_edition').click()
	}

	get controls() {
		return ['old_password', 'password', 'password_confirmation']
	}

	get buildReference() {
		return {
			old_password: null,
			password: null,
			password_confirmation: null,
			validators_reference: {
				password_confirmation: [this.confirmationValidation(this)]
			}
		}
	}
}
