import {Forms} from "@quantumms/cnc-stimulus-utils";
import {createGroup, updateGroup} from "../services/api_call";
import {openSnackbar} from "../services/shared";
import {I18n} from "../translations";
import {buttonLoader} from "../services/helper";

export default class extends Forms.FormsController {

    config = JSON.parse(this.element.dataset.groupForm);
    group_profile = `${this.element.dataset.profileCategory}_group`;
    group_categories = [];

    // on update
    group_details;
    group_member = [];

    beforeConnecting() {
        if (this.element.dataset.groupDetails) {
            this.group_details = JSON.parse(this.element.dataset.groupDetails);

            if(this.element.dataset.groupMembers) {
                this.group_members = JSON.parse(this.element.dataset.groupMembers);
            }
        }
    }

    state = {
        invalid: (context) => {
            return !context.state.valid;
        }
    }
    listeners = {
        valid: ['invalid']
    }

    formData = {
        validators_reference: {neglect: {}}
    }

    get buildReference() {
        this.config.forEach(configuration => {
            this.checkValidators(configuration)
            if (!configuration.required) {
                this.formData.validators_reference.neglect[configuration.name] = true
            }
        })
        return this.formData
    }

    checkValidators(config) {
        if (config.name === 'group_category') {
            this.formData[config.name] = []
        } else {
            this.formData[config.name] = this.group_details ? this.group_details[config.name] : null
        }

        if (config.validations) {
            config.validations.forEach(validation => {
                EditValidations[validation.name](validation, this.formData.validators_reference, config.name)
            })
        }

        if (['profile_picture', 'cover_photo'].includes(config.name)) this.group_details ? this.updateImagesOnLoad() : null;
        if (config.name === 'category') this.group_details ? this.updateCategoryOnLoad() : null;
    }

    addGroupCategory(event) {
        let category = event.currentTarget.value
        this.form.controls.category.setOnlyValues(category);
        let arr = this.group_categories
        if (arr.length < 3) {
            arr.includes(category) ? this.removeCategory(arr, category) : arr.push(category)
        } else {
            if (arr.includes(category)) {
                this.removeCategory(arr, category)
            } else {
                openSnackbar(I18n[window.currentLocale]['exceeds_category_limit'], 'error')
                event.preventDefault()
            }
        }
        if (this.group_categories.length > 0) this.form.controls.category.updateValidators();
    }

    removeCategory(arr, category) {
        arr.splice(this.group_categories.findIndex(data => data === category), 1)
        if (this.group_categories.length < 1) this.form.controls.category.updateValidators(false);
    }

    addNewCategory() {
        if (this.group_categories.length === 3) openSnackbar(I18n[window.currentLocale]['exceeds_category_limit'], 'error')
        else document.getElementById('group_category_create').classList.remove('hidden')
    }

    discardCategory() {
        document.getElementById('group_category_create').classList.add('hidden') // hides back the category field
    }

    cancelUpload(event) {
        const holder = document.getElementsByClassName('image-uploader');
        if (event.currentTarget.dataset.eventSource === 'profile_picture') {
            holder[0].style.display = 'inherit';
            document.getElementById('group-profile_picture_holder').style.display = 'none'
        } else {
            holder[1].style.display = 'inherit';
            document.getElementById('group-cover_photo_holder').style.display = 'none';
        }
    }

    save() {
        let params = {
            name: this.form.value.name,
            description: this.form.value.description,
            profile_category: this.group_profile,
            user_groups_attributes: [...this.form.value.members],
            profile_attributes: {
                profile_picture: this.form.value.profile_picture,
                cover_photo: this.form.value.cover_photo,
                category: this.group_categories,
                privacy: this.form.value.privacy
            }
        }

        this.form.updateValidators(false);

        buttonLoader('grp-create-btn', 'loader');

        createGroup(params, (response) => {
            openSnackbar(I18n[window.currentLocale]['group_created'], 'success');
            setTimeout(() => {
                window.location.href = `/iam/groups/${response.group}/overview`;
            }, 1500)
        });
    }

    update(event) {
        let params = {
            name: this.form.value.name,
            description: this.form.value.description,
            profile_category: this.group_profile,
            user_groups_attributes: [...this.form.value.members],
            profile_attributes: {
                profile_picture: this.form.value.profile_picture,
                cover_photo: this.form.value.cover_photo,
                category: this.group_categories,
                privacy: this.form.value.privacy
            }
        }

        this.form.updateValidators(false);

        buttonLoader('grp-update-btn', 'loader');

        let group_uuid = event.currentTarget.dataset.groupId;
        updateGroup(group_uuid, params, (response) => {
            openSnackbar(I18n[window.currentLocale]['group_updated'], 'success');
            setTimeout(() => {
                window.location.href = `/iam/groups/${response.group}/overview`;
            }, 1500)
        });
    }

    updateImagesOnLoad() {
        let dropbox = document.getElementsByClassName('image-uploader');

        if (this.group_details.profile_picture != null && this.group_details.cover_photo != null) {
            for (let i = 0; i < dropbox.length; i++) {
                dropbox[i].style.display = 'none';
            }
            document.getElementById('group-profile_picture_image').src = this.group_details.profile_picture['small'];
            document.getElementById('group-cover_photo_image').src = this.group_details.cover_photo['medium'];
        }

        if (this.group_details.profile_picture != null && this.group_details.cover_photo === null) {
            document.getElementById('group-profile_picture_image').src = this.group_details.profile_picture['small'];
            document.getElementById('group-cover_photo_holder').style.display = 'none';
            for (let i = 0; i < dropbox.length; i++) {
                if (dropbox[i].getAttribute('for') === 'group-profile_picture') {
                    dropbox[i].style.display = 'none';
                }
            }
        }

        if (this.group_details.profile_picture === null && this.group_details.cover_photo != null) {
            document.getElementById('group-cover_photo_image').src = this.group_details.cover_photo.medium;
            document.getElementById('group-profile_picture_holder').style.display = 'none';
            for (let i = 0; i < dropbox.length; i++) {
                if (dropbox[i].getAttribute('for') === 'group-cover_photo') {
                    dropbox[i].style.display = 'none';
                }
            }
        }

        if (!this.group_details.profile_picture && !this.group_details.cover_photo) {
            let dropbox = document.getElementsByClassName('image-uploader');
            for (let i = 0; i < dropbox.length; i++) {
                dropbox[i].style.display = 'block';
            }
            document.getElementById('group-profile_picture_holder').style.display = 'none';
            document.getElementById('group-cover_photo_holder').style.display = 'none';
        }
    }

    updateCategoryOnLoad() {
        let categories = document.getElementsByClassName('category-input');
        let selected_categories = this.group_details.category;
        this.group_categories = selected_categories;
        for (let i = 0; i < categories.length; i++) {
            selected_categories.includes(categories[i].value) ? categories[i].checked = true : null
        }
    }
}