import {Forms} from '@quantumms/cnc-stimulus-utils'
import {performAction} from "../services/auth";
import {identicalValidator} from "../services/validators";

export default class extends Forms.FormsController {
    static values = {type: String}
    state = {
        invalid: (context) => {
            return !context.state.valid
        }
    }
    maps = {
        sign_in: 'signInResource',
        forgot_password: 'forgotPasswordResource',
        reset_password: 'resetPasswordResource',
        set_password: 'resetPasswordResource'
    }
    listeners = {
        valid: ['invalid']
    }
    queries;

    beforeConnecting() {
        this.queries = new URLSearchParams(window.location.search)
    }

    connected() {
        if (['set_password', 'reset_password'].includes(this.typeValue)) {
            this.form.get('password_confirmation').addValidator(identicalValidator(this.form.get('password')))
            this.form.get('password').valueChanges.subscribe(value => {
                if (this.form.get('password_confirmation').value) {
                    this.form.get('password_confirmation').updateValidators();
                }
            })
        }

        document.getElementById('authentication').addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById("submit-btn").click();
            }
        });
    }


    checkCurrentRoute(context, type) {
        return context.state.locationPath == type
    }

    signInResource() {
        return {
            email: null,
            password: null,
            validators_reference: {
                email: [
                    Forms.QuantumValidators.pattern(/(^\w.*@\w.*\.\w)/, 'email')
                ]
            }
        }
    }

    performAction() {
        performAction(this.form.value, this.typeValue, function (response) {
            // show snackbar and redirect to the url
        }, {queries: this.queries});
    }

    resetPasswordResource() {
        return {
            password: null,
            password_confirmation: null,
            validators_reference: {}
        }
    }

    forgotPasswordResource() {
        return {
            email: null,
            validators_reference: {
                email: [
                    Forms.QuantumValidators.pattern(/(^\w.*@\w+\.\w)/, 'email')
                ]
            }
        }
    }

    get buildReference() {
        return this[this.maps[this.typeValue]]()
    }
}
