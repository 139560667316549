import {Controller} from "stimulus";
import {addUsersToGroup} from "../services/api_call";
import {AVATAR_COUNT, MIN_LIMIT} from "../services/constant";
import {appendDom, openSnackbar, setVdom} from "../services/shared";
import {I18n} from "../translations";

export default class extends Controller {
    removeFromSuggestionList(event) {
        let users = [event.currentTarget.dataset.user_uuid];
        let group_id = event.currentTarget.dataset.group_id;
        let membership = 'invite';

        addUsersToGroup(group_id, users, membership, null, (new_members) => {
                let users = new_members['data']['users'];
                let group = new_members['data']['group'];
                let member_count = new_members['data']['members']
                let followers = new_members['data']['followers']

                for (let i = 0; i < users.length; i++) { //dom_injection
                    document.getElementById(`suggested-${users[i].uuid}`).remove();

                    if (followers - AVATAR_COUNT < AVATAR_COUNT) {
                        setVdom(`/iam/new_group_member_avatar?user_uuid=${users[i].uuid}&group_id=${group.uuid}`,
                            'div', [], (vDom) => {
                                appendDom('member-avatar', vDom)
                            });
                    } else {
                        let last_avatar = document.getElementById('initials')
                        last_avatar.innerHTML = `+ ${followers - AVATAR_COUNT}`
                    }

                    if (member_count < MIN_LIMIT) {
                        setVdom(`/iam/newly_added_member?user_uuid=${users[i].uuid}&group_id=${group.uuid}`,
                            'div', [`p-[16px]`, `sm:rounded-[8px]`, `border-0`, `border-b`, `border-solid`, `border-gray-400`, `sm:border-[1px]`], (vDom) => {
                                appendDom('group-members-card-list', vDom)
                            });
                    }
                    else this.viewMore(group.uuid)
                }

                document.getElementById('member-count').innerHTML = `${I18n[window.currentLocale]['members']} (${member_count})`
                document.getElementById('group-followers-count').innerHTML = `${followers} ${I18n[window.currentLocale]['members']}`
                openSnackbar(I18n[window.currentLocale]['user_added_to_group'], 'success');
            }
        )
    }

    viewMore(uuid) {
        let dom = `<form method="post" action="/iam/view_more">
                                <button class="hidden cnc-btn cnc-btn--outline sm:inline-block" type="submit">View More</button>
                                <input type="hidden" name="id" value=${uuid}>
                                <input type="hidden" name="stream_target" value="members_data">
                              </form>`
        document.getElementById('view-more-member').innerHTML = '';
        document.getElementById('view-more-member').innerHTML = dom;
    }
}
