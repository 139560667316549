export const I18nES = {
	sign_in_success: 'Login successfully',
	link_sent_success: 'Link Sent successfully',
	reset_password_success: 'Password Reset successfully',
	set_password_success: 'Password set successfully',
	invalid_token_error: 'Invalid Token',
	validations: {
		email: 'Please enter valid email',
		required: 'Field is required',
		identical: 'Password needs to be identical'
	}
}
