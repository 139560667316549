import API from "./api";
import {HTML} from "./api";

export const openSnackbar = function (msg, status) {

    document.getElementById('snack-bar').innerHTML = `
		<div class="snack-bar snack-bar--${status} rounded-[4px]"
		data-controller="toggle">
		  <p class="mr-[20px]">
				${msg}
		  </p>
		  <i class="material-icons cursor-pointer block" data-action="click->toggle#toggle">close</i>
		 </div>`;

    setTimeout(function () {
        document.getElementById("snack-bar").innerHTML = "";
    }, 5000);
}

export const call = (path, value, callback, options) => {
    API(path, {
        method: options.verb, body: value
    }).then(response => {
        if (response.ok) {
            response.json().then(responseData => {
                callback(responseData)
            });
        } else {
            errorMessage(response);
        }
    })
}

export const errorMessage = function (response) {
    response.json().then(value => {
        if (value.error instanceof Object) {
            window.alert = openSnackbar(value.error.message, 'error')
        } else {
            window.alert = openSnackbar(value.error, 'error')

        }
    })
}

export const setVdom = (api, element = 'html', tokens = [''], callback) => {
    let finalApi = api
    finalApi += api.includes('?') ? '&api-call=true' : '?api-call=true'
    HTML(finalApi).then(response => {
        response.text().then(text => {
            let vDom = document.createElement(element)
            if (element === 'div') {
                vDom.classList.add(...tokens);
            }
            vDom.innerHTML = text
            callback(vDom, text)
        })
    })
}

export const appendDom = (domId, vDom) => {
    document.getElementById(domId).appendChild(vDom);
}

export const replaceDom = (domId, vDom) => {
    document.getElementById(domId).replaceWith(vDom);
}