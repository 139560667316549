import {Forms} from "@quantumms/cnc-stimulus-utils";
import Cropper from 'cropperjs';
import {assignUserPermission, getUserPermissionSet} from "../services/permission_set";
import {getPhotoUrl} from "../services/api_call";
import {openSnackbar} from "../services/shared";
import {updateProfilePicture} from "../services/auth";

export default class extends Forms.ControlsController {
    static targets = ['link', 'input']
    identity = this.element.dataset.identity;
    profile_picture;
    cover_photo;

    connect() {
        let form = document.getElementById('group_create');
        this.profile_picture = this.application.getControllerForElementAndIdentifier(form, 'group').form.controls.profile_picture
        this.cover_photo = this.application.getControllerForElementAndIdentifier(form, 'group').form.controls.cover_photo

        this.inputTarget.addEventListener('change', (event) => {
            const image = document.getElementById(`${this.identity}-profile-picture-image`);
            const file = event.target.files?.[0];
            if (file) {
                const modalCheckbox = document.getElementById(`${this.identity}-profile-cropper-modal`);
                if (!modalCheckbox.checked) modalCheckbox.click();

                if (modalCheckbox.checked) {
                    const url = URL.createObjectURL(file);
                    image.src = url;
                    image.onload = () => {
                        this.cropImage(image, this.identity);
                    };
                }
            }
        });
    }

    cropImage(image, id) {
        let cropper_ctl = document.getElementsByClassName('cropper-ctl');
        for(let i=0; i < cropper_ctl.length; i++) {
            cropper_ctl[i].style.display = 'block';
        }

        let croppedData;
        const temp_aspectRatio = id === 'group-profile_picture' ? 1/1 : 142/35;
        const cropper = new Cropper(image, {
            aspectRatio: temp_aspectRatio,
            zoomable: false,
            viewMode: 1,
            crop(event) {
                croppedData = {
                    width: event.detail.width,
                    height: event.detail.height
                };
            },

            ready() {
                document.getElementById(`${id}-crop-btn`).addEventListener('click', () => {
                    const canvas = cropper.getCroppedCanvas();
                    const image = document.getElementById(`${id}-profile-picture-image`);
                    image.src = canvas.toDataURL();
                    image.onload = () => {
                        cropper.destroy();
                        let cropper_ctl = document.getElementsByClassName('cropper-ctl');
                        for(let i=0; i < cropper_ctl.length; i++) {
                            cropper_ctl[i].style.display = 'none';
                        }
                    };
                });

                document.getElementById(`${id}-rotate-btn`).addEventListener('click', () => {
                    cropper.rotate(90);
                });
            },
        });
    }

    saveImage() {
        const loader_holder= document.getElementsByClassName('upload-loader');
        let _loader = document.getElementById('image-loader').innerHTML;
        for(let i=0; i < loader_holder.length; i++) {

            loader_holder[i].style.width = '150px';
            loader_holder[i].innerHTML = _loader;
        }

        const modalCheckbox = document.getElementById(`${this.identity}-profile-cropper-modal`);

        if (modalCheckbox.checked) {
            const imgEl = document.getElementById(`${this.identity}-profile-picture-image`);
            const imgUrl = imgEl.src;

            let _this = this;
            this.loadXHR(imgUrl, this.identity).then((imgBlob) => {
                let id = imgBlob.id // used to separate the invocation of different backend api calls (iam profiles/ iam group)
                imgBlob.filename = `new_name_secure.${imgBlob.type.split('/')[1]}`

                let _this_ = _this;

                if(id === 'group-profile_picture' || id === 'group-cover_photo') {
                    getPhotoUrl(imgBlob, id, function (value) {
                        const display = document.getElementById(value.id);
                        let id = display.id.replace('_holder', '_image');
                        let targetImage = document.getElementById(id);

                        const holder = document.getElementsByClassName('image-uploader');
                        if(id === 'group-profile_picture_image') {
                            targetImage.src = value.url.small;
                            holder[0].style.display = 'none';
                            _this.profile_picture.setOnlyValues(value.url);
                        }
                        else {
                            targetImage.src = value.url.medium;
                            holder[1].style.display = 'none';
                            _this.cover_photo.setOnlyValues(value.url);
                        }

                        display.classList.remove('hidden');
                        modalCheckbox.click();

                        let action_name = window.location.href.split('/').pop();
                        if(action_name === 'edit') display.style.display = 'block'

                        for(let i=0; i < loader_holder.length; i++) {
                            loader_holder[i].innerHTML = 'save changes';
                        }
                    });
                }
                else {
                    updateProfilePicture(imgBlob, id, function (value) {
                        const profilePicture = document.getElementById(`${value.id}-profile-picture-image`);
                        profilePicture.src = imgUrl;
                        modalCheckbox.click();
                    });
                }
            });
        }
    }

    changePassword() {
        this.openModal('password_change_modal_edition')
    }

    editProfile() {
        this.openModal('auth_modal_edition')
    }

    cancelCropper() {
        let cropper_ctl = document.getElementsByClassName('cropper-ctl');
        for(let i=0; i < cropper_ctl.length; i++) {
            cropper_ctl[i].style.display = 'block';
        }

        document.getElementById(`${this.identity}-crop-btn`).click();
        document.getElementById(`${this.identity}-profile-cropper-modal`).click();
    }

    getPermissionSets(event) {
        getUserPermissionSet(event.currentTarget.dataset.profileUuid)
    }

    assignPermission(event) {
        let pset = document.querySelector('input[name="permission-set"]:checked').value
        assignUserPermission(pset, event.currentTarget.dataset.currentUser, (value) => {
            this.linkTarget.href = window.location.href
            this.linkTarget.click();
        });
    }

    openModal(id) {
        const editState = document.getElementById(id)
        if (!editState.checked) {
            editState.click()
        }
    }

    loadXHR(url, id) {
        return new Promise(function (resolve, reject) {
            try {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.onerror = function () {
                    reject("Network error.")
                };
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        xhr.response.id = id
                        resolve(xhr.response)
                    } else {
                        reject("Loading error:" + xhr.statusText)
                    }
                };
                xhr.send();
            } catch (err) {
                reject(err.message)
            }
        });
    }

    checkUpload(event) {
        // for iam groups
        const extensions = ['png', 'jpg', 'jpeg']
        const files = event.target.files;
        let fileExtension = files[0].type.split("/").pop();
        if(!extensions.includes(fileExtension)){
            openSnackbar('Content not an image. Cannot upload.','error')
        }
    }
}