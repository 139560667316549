import {Controller} from "stimulus"
import {setVdom} from "../services/shared";

export default class extends Controller {
    static targets = ['filter', 'roles']

    connect() {
        this.toggleContent();
    }

    clear() {
        JSON.parse(this.rolesTarget.dataset.value).forEach(role => {
            document.getElementById(role.name).checked = false
        })
        let statuses = ['active', 'inactive']
        statuses.forEach(status => {
            document.getElementById(status).checked = false
        })
        setVdom('/iam/filter', 'div', [], (vDom) => {
            document.getElementById('user_index_body').replaceWith(vDom.children[0])
        })
        this.closeFilter()
    }

    toggleContent() {
        document.querySelectorAll('.toggle-content').forEach((e, index) => {
            e.addEventListener('click', function () {
                let element = document.getElementById('filter' + index.toString());
                let condition = element.classList.contains('hidden');
                element.classList.remove(condition ? 'hidden' : 'block');
                element.classList.add(condition ? 'block' : 'hidden');
            })
        })
    }

    closeFilter() {
        document.getElementById('close-filter').click()
    }
}
