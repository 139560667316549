import {Controller} from "stimulus"
import {setVdom} from "../services/shared";

export default class extends Controller {
    static targets = ["link", "roles", "status", "filter", "input"]

    filter() {
        const roles = []
        const statuses = []

        this.rolesTargets.forEach(role => {
            if (role.checked) {
                roles.push(role.value)
            }
        })

        this.statusTargets.forEach(status => {
            if (status.checked) {
                statuses.push(status.value)
            }
        })
        let append_string = ""

        if (roles.length > 1) {
            roles.forEach(role => {
                append_string += "role[]=" + role + "&"
            })
        } else if (roles.length === 0) {
            append_string = append_string
        } else {
            append_string += "role=" + roles[0] + "&"
        }

        if (statuses.length > 1) {
            statuses.forEach(stat => {
                append_string += "by_activity[]=" + stat + "&"
            })
        } else if (statuses.length === 0) {
            append_string = append_string
        } else {
            append_string += "by_activity=" + statuses[0] + "&"
        }

        setVdom('/iam/filter' + "?" + append_string, 'div', [], (vDom) => {
            document.getElementById('user_index_body').replaceWith(vDom.children[0])
            this.closeFilter()
        })
    }

    search() {
        let text = this.ltrim(this.inputTarget.value)
        if (text.length >= 3) {
            setVdom('/iam/filter' + "?query=" + text, 'div', [], (vDom) => {
                document.getElementById('user_index_body').replaceWith(vDom.children[0])
            })
        } else {
            setVdom('/iam/filter', 'div', [], (vDom) => {
                document.getElementById('user_index_body').replaceWith(vDom.children[0])
            })
        }
    }

    ltrim(str) {
        if (!str) return str;
        return str.replace(/^\s+/g, '');
    }

    closeFilter() {
        document.getElementById('close-filter').click()
    }
}
