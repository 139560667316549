import { Controller } from "stimulus";
import {addUsersToGroup, removeMember} from "../services/api_call";
import {openSnackbar} from "../services/shared";
import {I18n} from "../translations";
import {buttonLoader} from "../services/helper";

export default class extends Controller {

    requestToJoinGroup(event) {
        let data = event.currentTarget.dataset
        let user_id = [data.userId]
        let membership = data.membership
        let group_id = data.groupId
        let radix = data.radix
        let targetId = event.currentTarget.id;

        buttonLoader(targetId, 'loader2');

        addUsersToGroup(group_id, user_id, membership, radix, (res) => {
            let groupId = res.data.group.uuid;
            if(res.radix === 'group-listing') document.getElementById(groupId).remove();
            else {
                let parent = document.getElementById(targetId);
                parent.innerHTML = '';
                let dom = `<i class="material-icons align-middle">check</i>
              <span class="align-middle">${I18n[window.currentLocale]['request_sent']}</span>`
                parent.innerHTML = dom;
            }
            openSnackbar(I18n[window.currentLocale]['request_to_group'], 'success');
        })
    }

    cancelRequest(event){
        let data = event.currentTarget.dataset
        let user_id = [data.userId]
        let group_id = data.groupId

        buttonLoader(event.currentTarget.id, 'loader2');

        removeMember(group_id, user_id, (res) => {
            document.getElementById(res.group).remove();
            openSnackbar(I18n[window.currentLocale]['cancel_join_request'], 'success');
        })
    }

    removeLoader(event) {
        const groupId = event.currentTarget.dataset.groupId;
        let inviteBtn = document.getElementById(`invite-group-members-btn-${groupId}`);

        inviteBtn.removeAttribute('style');
        inviteBtn.innerHTML = I18n[window.currentLocale]['invite'];
    }
}
