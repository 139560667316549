import { I18n } from '../translations'
import {call, openSnackbar} from "./shared";

export const createPermissionSet = function (data, callback) {
	call('/api/v2/iam/permissions', data, callback, {
		verb: 'POST'
	})
}

export const updatePermissionSet = function (data, callback) {
	call(`/api/v2/iam/permissions/${data.uuid}`, data, callback, {
		verb: 'PUT'
	})
}

export const performPermissionToggle = function (event) {
	let data = JSON.parse(event.currentTarget.dataset.options)

	if (!data.action) {
		Object.keys(data.permission.actions).forEach(action => {
			let ele = document.getElementById(`${data.permission.uuid}_permission_${action}`)
			ele.checked = false
		});
	} else {
		let ele = document.getElementById(`${data.permission.uuid}_permission_set`)
		ele.checked = true
	}

	return data
}

export const getUserPermissionSet = function (uuid) {
	fetch(`/iam/profiles/${uuid}/permission_sets?api-call`, {
		method: 'GET',
		headers: {
			'Content-Type': 'text/html'
		},
	}).then(response => response.text().then(response => {
		let vDom = document.createElement('div')
		vDom.innerHTML = response
		document.getElementById('user-permission-set').children[0].replaceWith(vDom.querySelector('div[id=user-permission-set-replacement]'))
		// document.getElementById('assign-permission').click()
	}))
}

export const assignUserPermission = function (pset, current_user, callback) {
	call(`/api/v2/iam/permissions/${pset}/assign`, { user: current_user }, callback, {
		verb: 'PUT'
	})
}
