import {Forms} from '@quantumms/cnc-stimulus-utils'
import {invite} from "../services/auth";


export default class extends Forms.FormsController {
    static targets = ['cancelButton', 'list']

    state = {
        invalid: (context) => {
            return !context.state.valid;
        }
    }

    listeners = {
        valid: ['invalid']
    }

    invite() {
        invite(this.postData,(value) => {
					 if (document.getElementById('add-user')) {
					 		this.listTarget.click();
					 }
        })
    }

    cancelInvite() {
        let dataset = this.cancelButtonTarget.dataset['idDeletion']
        if (dataset) {
            let elem = document.getElementsByClassName((dataset))[0]
            let counter = elem.childNodes.length
            while (counter) {
                elem.childNodes[counter - 1].remove()
                counter--;
            }
        }
    }

    get postData() {
    	return {
				email: this.form.value.email,
				role_id: this.form.value.role,
				profile_attributes: this.form.value
			}
		}

    get buildReference() {
        return {
            first_name: null,
            salutation: null,
            last_name: null,
            email: null,
            role: null,
            department: null,
            designation: null,
            validators_reference: {
                email: [
                    Forms.QuantumValidators.pattern(/(^\w.*@\w.*\.\w)/, 'email')
                ]
            }
        }
    }
}
