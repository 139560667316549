import {Forms} from "@quantumms/cnc-stimulus-utils";
import {createGroupCategory} from "../services/api_call";
import {appendDom, openSnackbar, setVdom} from "../services/shared";
import {I18n} from "../translations";

export default class extends Forms.FormsController {
    formData = {}

    get buildReference() {
        this.formData['name'] = null

        return this.formData
    }

    saveCategory(event) {
        createGroupCategory(this.form.value, (response) => {
            setVdom(`/iam/append_new_category?id=${response.category.id}&name=${response.category.name}`, 'div', [],(vDom) => {
                appendDom('group-category-chips', vDom)
            });
            openSnackbar(I18n[window.currentLocale]['new_category'], 'success');
        })
        document.getElementById('group-category').value = null;
    }
}
