export const I18nEN = {
    sign_in_success: 'Login successfully',
    link_sent_success: 'Link Sent successfully',
    reset_password_success: 'Password Reset successfully',
    set_password_success: 'Password set successfully',
    invalid_token_error: 'Invalid Token',
    selected_people: 'Selected People',
    member_promote: 'Member has been promoted to admin successfully.',
    member_demote: 'Admin has been demoted to member successfully.',
    user_added_to_group: 'User has been added.',
    member_removal: 'User successfully removed.',
    request_to_group: 'Join request has been sent.',
    cancel_join_request: 'Join request has been cancelled.',
	new_category: 'New category added successfully.',
	exceeds_category_limit: 'Exceeded category limit. Cannot add more tags.',
	group_created: 'Group successfully created.',
	group_updated: 'Group successfully updated.',
	members: 'Members',
    admins: 'Admins',
    promotion: 'Promote to admin',
    demotion: 'Revert to member',
    request_sent: 'Request Sent',
    delete_group: 'Group deletion successful.',
    invalid_invite: 'Select at least one user.',
    join_request: 'Join Request',
    reject_request: 'Join request has been successfully rejected.',
    group_delete: 'Delete Group?',
    delete_warning: 'Group content related to this group will be archived. You can unarchive News, Events and Articles from Archived tab.',
    delete: 'Delete',
    remove_user: 'Remove User?',
    remove_user_warning: 'Once you remove the user, the action cannot be undone.',
    remove: 'Remove',
    request_reject: 'Reject Request?',
    request_reject_warning: 'Once you reject the request, the action cannot be undone.',
    reject: 'Reject',
    accept_request: 'Accept Request?',
    accept_request_confirm: 'Are you sure you want to accept this request?',
    accept_all_request_confirm: 'Are you sure you want to accept all the requests?',
    accept: 'Accept',
    validations: {
        email: 'Please enter valid email',
        required: 'Field is required',
        identical: 'Password needs to be identical',
        password_mismatch: 'Password needs to be identical'
    },
    invite: 'Invite'
}
