import {I18nEN} from "./en";
import {I18nDE} from "./de";
import {I18nES} from "./es";
import {I18nFR} from "./fr";
import {I18nIT} from "./it";

export const I18n = {
    en: I18nEN,
    de: I18nDE,
    es: I18nES,
    fr: I18nFR,
    it: I18nIT
}