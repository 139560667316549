import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['previous', 'next'];

    goNext() {
        this.nextTarget.href = window.location.search.replace(this.currentPage(this.nextTarget), `page=${+this.nextTarget.dataset.currentPage + 1}`)
        if (!window.location.search.includes('page')) {
            window.location.search = `page=${+this.nextTarget.dataset.currentPage + 1}`
            this.nextTarget.href = window.location.search.replace('', `page=${+this.nextTarget.dataset.currentPage + 1}`)
        }
        this.nextTarget.click()
    }

    goPrevious() {
        this.previousTarget.href = window.location.search.replace(this.currentPage(this.previousTarget), `page=${+this.previousTarget.dataset.currentPage - 1}`)
        this.previousTarget.click()
    }

    currentPage(target) {
        return 'page=' + target.dataset.currentPage
    }
}
