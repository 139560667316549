import {Controller} from "stimulus"
import {method} from "stimulus-use/dist/support";
import {CncController} from "@quantumms/cnc-stimulus-utils";
import {useDebounce} from "stimulus-use";
import {bulkDeleteUser, activateUser} from "../services/auth";

export default class extends CncController {
    static targets = ["users", 'selectedUser', 'link']
    selectedUserDocument;
    selectedUsers = {
        index: {},
        data: []
    };
    deletedUser;

    connected() {
        this.selectedUserDocument = this.selectedUserTarget.cloneNode(true);
        this.deletedUser = document.getElementById('deleted_user_list');
    }

    selectAlllUser(event) {
        let data = JSON.parse(event.currentTarget.dataset.user)
        this.selectedUsers = {
            index: {},
            data: []
        };

        if (event.currentTarget.checked) {
            data.forEach(user => {
                this.selectedUsers.index[user.uuid] = this.selectedUsers.data.length
                this.selectedUsers.data.push(user)
            })
        } else {
            this.selectedUsers = {
                index: {},
                data: []
            };
        }

        this.selectedUsers.data.length ? document.getElementById('user-select-text').innerHTML = this.selectedUsers.data.length.toString() + ' user selected' : document.getElementById('user-select-text').innerHTML = ''

        const element = document.getElementById('user-delete-content');
        element.classList.remove(!this.selectedUsers.data.length ? 'flex' : 'hidden');
        element.classList.add(this.selectedUsers.data.length ? 'flex' : 'hidden');
        const allUserChecked = event.currentTarget.checked;
        document.querySelectorAll('.delete-content').forEach((element) => element.checked = allUserChecked);
    }

    selectNewUser(event, update = true) {
        event.stopPropagation();
        let data = JSON.parse(event.currentTarget.dataset.user)
        if (event.currentTarget.checked) {
            this.selectedUsers.index[data.uuid] = this.selectedUsers.data.length
            this.selectedUsers.data.push(data)
            let checked = false
            let user_elements = document.querySelectorAll('.delete-content')
            for (let i = 0; i < user_elements.length; i++) {
                if (!user_elements[i].checked) {
                    checked = false
                    break;
                } else {
                    checked = true
                }
            }
            document.getElementById('select-all-user').checked = checked
        } else {
            let index = this.selectedUsers.index[data.uuid]
            this.selectedUsers.data.splice(index, 1)
            delete this.selectedUsers.index[data.uuid]
            for (let indexKey in this.selectedUsers.index) {
                this.selectedUsers.index[indexKey] -= 1
            }
            document.getElementById('select-all-user').checked = false
        }

        if (update) {
            this.selectedUsers.data.length ? document.getElementById('user-select-text').innerHTML = this.selectedUsers.data.length.toString() + ' user selected' : document.getElementById('user-select-text').innerHTML = ''
            const element = document.getElementById('user-delete-content');
            element.classList.remove(!this.selectedUsers.data.length ? 'flex' : 'hidden');
            element.classList.add(this.selectedUsers.data.length ? 'flex' : 'hidden');
        }
    }

    deleteUser() {
        this.deletedUser.innerHTML = ''
        let users = this.selectedUsers.data
        for (let i = 0; i < users.length; i++) {
            let user_element = this.selectedUserDocument.cloneNode(true)
            user_element.querySelector('img').src = users[i].photo_path || user_element.querySelector('img').src
            user_element.querySelector('[data-input=name-value]').innerText = users[i].name
            user_element.querySelector('[data-input=role-value]').innerText = users[i].role
            this.deletedUser.appendChild(user_element)
        }
    }

    delete() {
        let users = []
        this.selectedUsers.data.forEach(user => {
            users.push(user.uuid)
        })

        bulkDeleteUser(users, () => {
        })
        setTimeout(function () {
            window.location.reload(true)
        }, 2000);
    }

    activate(event) {
        event.stopPropagation();
        let options = JSON.parse(event.currentTarget.dataset.options)
        let user = JSON.parse(options.user)
        let data = event.currentTarget.checked
        let action = ''
        let id = event.currentTarget.id
        if (data === true) {
            action = 'Activated'
        } else {
            action = 'Deactivated'
        }

        activateUser(data, user.uuid, action, (response) => {
            if (response.done) {
                if (action == 'Activated') {
                    let data = document.querySelectorAll(`#active_${id}`);
                    data.forEach(element => {
                        element.classList.remove('text-gray-600')
                    })
                    let label = document.getElementById(`user_label_${id}`)
                    label.classList.remove('bg-gray-400')
                    label.classList.add('bg-success')
                    label.classList.add('text-white')
                    label.innerText = 'Active'
                } else {
                    let data = document.querySelectorAll(`#active_${id}`);
                    data.forEach(element => {
                        element.classList.add('text-gray-600')
                    })
                    let label = document.getElementById(`user_label_${id}`)
                    label.classList.remove('bg-success')
                    label.classList.remove('text-white')
                    label.classList.add('bg-gray-400')
                    label.innerText = 'Inactive'
                }
            }
        })
    }

    redirectToUser(event) {
        let linkTarget = document.getElementById('hit-link')
        linkTarget.href = linkTarget.dataset.mainLink + '/' + event.currentTarget.dataset.userReference
        linkTarget.click();
    }
}
