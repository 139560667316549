import {Controller} from 'stimulus'
import {appendDom, openSnackbar, setVdom} from "../services/shared";
import {I18n} from "../translations";
import {AVATAR_COUNT} from "../services/constant";
import {addUsersToGroup} from "../services/api_call";
import {buttonLoader} from "../services/helper";

export default class extends Controller {
    selected_users = []

    check(event) {
        event.stopPropagation();
        let target = event.currentTarget;
        this.selected_users = [...document.querySelectorAll('#selected-users div > div')].map(({id}) => id)

        if (target.checked) {
            let data = JSON.parse(target.dataset.user);

            if (!this.selected_users.includes(data['uuid'])) {
                this.selected_users.push(data['uuid'])
            }
            let user = document.getElementById(`invite-${data['uuid']}`)
            user.remove();

            this.addChip(data, this.selected_users.length); // add user chip on check
            this.refreshInviteList(this.selected_users, event.currentTarget.dataset.groupId);
        }

    }

    addChip(user, count) {
        setVdom(`/iam/select_member?name=${user.name}&email=${user.email}&uuid=${user.uuid}&imgLink=${user.photo_path}`, 'div', [], (vDom) => {
            appendDom('selected-users', vDom)
        });
        this.updateMemberCount(count);
    }

    refreshInviteList(invitedUsers, groupId) {
        const selectedUsers = encodeURIComponent(JSON.stringify(invitedUsers));
        fetch(`/iam/groups/${groupId}/user_suggestions?selected_users=${selectedUsers}`, {})
          .then(response => response.text()).then(html => {
              let tmpDom = document.createElement('div');
              tmpDom.innerHTML = html;
              let newDom = document.createElement('div');
              newDom.innerHTML = tmpDom.querySelector('[id="members"]').innerHTML;
              document.getElementById('members').replaceChildren(newDom.querySelector('[id="invite-users"]'));
        });
    }

    removeChip(event) {
        let dom = JSON.parse(event.currentTarget.dataset.user);
        let user_profile = event.currentTarget.dataset.userRecord; // for state management

        if (this.selected_users.includes(dom.uuid)) {
            this.selected_users = this.selected_users.filter(id => id !== dom.uuid);
            document.getElementById(dom.uuid).parentNode.remove();

            this.updateMemberCount(this.selected_users.length);

            this.insertToSuggestList(dom, user_profile);
        }
    }

    insertToSuggestList(dom, user_profile) {
        let target = document.getElementById('invite-users');
        let child = target.firstElementChild.cloneNode(true);

        child.setAttribute('id', dom.uuid);
        if (dom.imgLink !== null) child.children[0].children[0].setAttribute('src', dom.imgLink);
        child.children[0].children[1].children[0].innerHTML = dom.label;
        child.children[0].children[1].children[1].innerHTML = dom.email;
        child.children[1].firstElementChild.setAttribute('data-user', user_profile);

        target.insertBefore(child, target.firstChild);
    }

    updateMemberCount(count) {
        const content = `${I18n[window.currentLocale]['selected_people']} (${count})`
        document.getElementById('select-member-count').innerHTML = content
    }

    inviteSelectedUsers(event) {
        let groupId = event.currentTarget.dataset.groupId
        let params = this.selected_users
        let membership = 'invite'

        buttonLoader(event.currentTarget.id, 'loader');

        if (this.selected_users.length > 0) {
            addUsersToGroup(groupId, params, membership, null, (res) => {
                let members = res['data']['members'];
                let users = res['data']['users'];
                let group = res['data']['group'];
                let followers = res['data']['followers'];

                document.getElementById('invite_members_modal').click();

                let avatar_count = document.getElementById('member-avatar').childElementCount

                if (avatar_count < AVATAR_COUNT) {
                    for (let k = 0; k < users.length; k++) { //dom_injection
                        if (avatar_count + (k + 1) > AVATAR_COUNT) {
                            this.viewMore(group.uuid);
                            break;
                        }

                        setVdom(`/iam/newly_added_member?user_uuid=${users[k].uuid}&group_id=${group.uuid}`,
                            'div', [`p-[16px]`, `sm:rounded-[8px]`, `border-0`, `border-b`, `border-solid`, `border-gray-400`, `sm:border-[1px]`], (vDom) => {
                                appendDom('group-members-card-list', vDom)
                            });
                    }
                } else {
                    this.viewMore(group.uuid);
                }

                let avatarDom = document.getElementById('member-avatar');
                if (followers - AVATAR_COUNT < AVATAR_COUNT) avatarDom.lastElementChild.remove();
                else {
                    let new_member_count = followers - AVATAR_COUNT;
                    if(document.getElementById('initials-more_members')) {
                        document.getElementById('initials-more_members').innerHTML = `+ ${new_member_count}`;
                    }
                }
                if(document.getElementById('member-count'))
                    document.getElementById('member-count').innerHTML = `${I18n[window.currentLocale]['members']} (${members})`
                if(document.getElementById('group-followers-count'))
                    document.getElementById('group-followers-count').innerHTML = `${followers} ${I18n[window.currentLocale]['members']}`
                openSnackbar(I18n[window.currentLocale]['user_added_to_group'], 'success');
            })
        } else {
            openSnackbar(I18n[window.currentLocale]['invalid_invite'], 'error');
        }
    }

    cancelSelect() {
        this.removeChildElements();
        this.clearSearchInput();
        this.selected_users.length = 0;
        let selected_users = document.getElementById('selected-users');

        for (let i = 0; i < selected_users.childElementCount; i++) {
            let dom = JSON.parse(selected_users.children[i].children[0].children[2].getAttribute('data-user'));
            let user_profile = selected_users.children[i].children[0].children[2].getAttribute('data-user-record');
            this.insertToSuggestList(dom, user_profile);
        }

        selected_users.innerHTML = '';
        let member_count = document.getElementById('select-member-count');
        member_count.innerHTML = `${I18n[window.currentLocale]['selected_people']} (0)`
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    viewMore(uuid) {
        let dom = `<form method="post" action="/iam/view_more">
                                <button class="hidden cnc-btn cnc-btn--outline sm:inline-block" type="submit">View More</button>
                                <input type="hidden" name="id" value=${uuid}>
                                <input type="hidden" name="stream_target" value="members_data">
                              </form>`

        let viewMoreDom = document.getElementById('view-more-member');
        if(viewMoreDom) viewMoreDom.innerHTML = dom;
    }

    removeChildElements() {
        const selectedUserElement = document.getElementById('selected-users');
        selectedUserElement.replaceChildren();
    }

    clearSearchInput() {
        document.querySelector('form[data-name=search] input[name=query]').value = "";
    }
}