import { Forms } from '@quantumms/cnc-stimulus-utils'
import {EditValidations} from "../services/profile_edit_service";
import {updateProfile} from "../services/auth";



export default class extends Forms.FormsController {
	static targets = ['edit']

	dataset = JSON.parse(this.element.dataset.profileData)
	configurationSet = JSON.parse(this.element.dataset.profileConfiguration)
	formData = {
		validators_reference: {}
	}

	state = {
		invalid: (context) => {
			return !context.state.valid;
		}
	}

	listeners = {
		valid: ['invalid']
	}

	setFormReferences() {
		this.configurationSet.attributes.forEach(configuration => {
			this.formData[configuration.name] = this.dataset[configuration.name]
			if (configuration.validations) {
				configuration.validations.forEach(validation => {
					EditValidations[validation.name](validation, this.formData.validators_reference, configuration.name)
				})
			}
		})
	}

	save() {
		let user = JSON.parse(this.editTarget.dataset.profileData).uuid

		updateProfile(user, this.form.value, (value) => {
			this.updateDisplayData()
			this.cancel();
			this.setParentState(value)
		})
	}

	setParentState(value) {
		localStorage.setItem('profile', JSON.stringify({ name: this.name, image: value.user.photo_path }))
		let sideNav = document.getElementById('profile-overlay-details')
			if (sideNav) {
				['first', 'second'].forEach(vo => {
					sideNav.click();
				})
			}
	}

	cancel() {
		document.getElementById('auth_modal_edition').click()
	}

	updateDisplayData() {
		this.configurationSet.attributes.forEach(configuration => {
			let elem = document.getElementById(`${configuration.name}_display_data`)
			elem.innerText = this.form.get(configuration.name).value
		})
		document.getElementById('profile-name-display-data').innerText = this.name
	}

	get name() {
		return this.form.get('first_name').value + ' ' + this.form.get('last_name').value
	}

	get buildReference() {
		this.setFormReferences();
		return this.formData
	}
}
