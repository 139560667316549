const API = function (api, options) {
    return fetch(api, {
        method: options.method,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': window.currentLocale
        },
        body: options.body ? JSON.stringify(options.body) : null
    })
}

export const HTML = function (api) {
    return fetch(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/html',
        }
    })
}

export default API
