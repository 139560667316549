import {Controller} from 'stimulus';
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller {
    static targets = ['form']

    entitySearch() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
                Turbo.navigator.submitForm(this.formTarget)
            }, 200
        )
    }
}