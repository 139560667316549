export const I18nDE = {
	sign_in_success: 'Login successfully',
	link_sent_success: 'Link Sent successfully',
	reset_password_success: 'Password Reset successfully',
	set_password_success: 'Password set successfully',
	invalid_token_error: 'Invalid Token',
	selected_people: 'Ausgewählte Personen.',
	member_promote: 'Mitglied ist nun ein Admin',
	member_demote: 'Admin ist nun ein Mitglied',
	user_added_to_group: 'Benutzer wurde hinzugefügt',
	member_removal: 'Benutzer wurde entfernt',
	request_to_group: 'Join request has been sent.',
	cancel_join_request: 'Beitrittsanfrage wurde zurückgezogen',
	new_category: 'Neue Kategorie wurde hinzugefügt',
	exceeds_category_limit: 'Kategorienlimit wurde erreicht. Weitere Kategorien können nicht hinzugefügt werden.',
	group_created: 'Gruppe erstellt',
	group_updated: 'Gruppe aktualisiert',
	members: 'Mitglieder',
	admins: 'Admins',
	promotion: 'Promote to admin',
	demotion: 'Revert to member',
	request_sent: 'Versendete Anfragen',
	group_delete: 'Gruppe Löschen?',
	invalid_invite: 'Wählen Sie mindestens einen Benutzer aus',
	join_request: 'Beitrittsanfrage',
	reject_request: 'Beitrittsanfrage wurde abgelehnt',
	delete_warning: 'Group content related to this group will be archived. You can unarchive News, Events and Articles from Archived tab.',
	delete: 'Löschen',
	remove_user: 'Entfernen Mitglied?',
	remove_user_warning: 'Once you remove the user, the action cannot be undone.',
	remove: 'Entfernen',
	request_reject: 'Reject Request?',
	request_reject_warning: 'Once you reject the request, the action cannot be undone.',
	reject: 'Ablehnen',
	accept_request: 'Accept Request?',
	accept_request_confirm: 'Are you sure you want to accept this request?',
	accept_all_request_confirm: 'Are you sure you want to accept all the requests?',
	accept: 'Akzeptieren',
	validations: {
		email: 'Please enter valid email',
		required: 'Das ist ein Pflichtfeld',
		identical: 'Password needs to be identical'
	},
	invite: 'Einladen'
}
