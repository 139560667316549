import {Forms} from '@quantumms/cnc-stimulus-utils';

export default class extends Forms.ControlsController {
    // connect() {
    //     let element = document.getElementById('group-chips')
    //     debugger
    //     if (element) {
    //         debugger
    //         element.checked = true
    //         this.control.setValue(element.dataset.value)
    //     }
    // }
    //
    // display(event) {
    //     debugger
    //     this.control.setValue(event.currentTarget.dataset.value)
    // }
}
