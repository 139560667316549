import {call} from "./shared";

export const updateUserRole = function (id, data, role_action, event, callback) {
    call(
        `/api/v2/iam/groups/${id}/user_group`,
        {users: data, role_action: role_action, event: event},
        function (response) {
            callback(response);
        },
        {verb: 'PUT'})
}

export const addUsersToGroup = function (id, data, membership, radix, callback) {
    call(
        `/api/v2/iam/groups/${id}/add_user`,
        {users: data, membership: membership, radix: radix},
        function (response) {
            callback(response)
        },
        {verb: 'PUT'}
    )
}

export const removeMember = function (id, data, callback) {
    call(
        `/api/v2/iam/groups/${id}/remove_user`,
        {users: data},
        function (response) {
            callback(response)
        },
        {verb: 'PUT'}
    )
}

export const createGroup = function (data, callback) {
    call(
        '/api/v2/iam/groups',
        {group: data},
        function (value) {
            callback(value);
        },
        {verb: 'POST'}
    )
}

export const updateGroup = function (id, data, callback) {
    call(
        `/api/v2/iam/groups/${id}`,
        {group: data},
        function (value) {
            callback(value);
        },
        {verb: 'PUT'}
    )
}

export const deleteGroup = function (id, callback) {
    call(
        `/api/v2/iam/groups/${id}`,
        {},
        function () {
            callback();
        },
        {verb: 'DELETE'}
    )
}

export const createGroupCategory = function (data, callback) {
    call(
        '/api/v2/iam/create_group_category',
        {group_category: data},
        function (value) {
            callback(value)
        },
        {verb: 'POST'}
    )
}

export const getPhotoUrl = function (image, id, callback) {
    let data = new FormData()
    data.append('photo', image)
    data.append('id', id)

    fetch('/api/v2/iam/upload_photo', {
        method: 'post',
        body: data,
    }).then(response => {
        if (response.ok) {
            response.json().then(value => {
                callback(value)
            });
        } else {
            response.json().then(value => {
            })
        }
    })
}
