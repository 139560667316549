import {Controller} from "stimulus"

export default class extends Controller {
	static targets = ['link']
	redirect(event) {
		let target = event.currentTarget.dataset
		this.linkTarget.href = this.linkTarget.href + `/${target.id}`
		this.linkTarget.click();
	}
}
