import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['toggleable'];

    toggle() {
        document.getElementById("snack-bar").innerHTML = "";
    }

}
